import React from 'react'
import { Container } from '@mantine/core'
import Base from './Base'
import PropTypes from 'prop-types'

export default function Content ({ children }) {
  return (
    <Base>
      <Container fluid px='xs'>
        {children}
      </Container>
    </Base>
  )
}

Content.propTypes = {
  children: PropTypes.node
}
