import { apiSlice, ApiTags } from '../../../../api';
import { cycleListTag, paramsToQuery } from '../../../../util/queries';
import { injectNullFlags } from '../queryUtil';

export const CycleStagesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStagesForCycle: builder.query({
      query: ({ cycleId, ...params }) => `invites/stages/cycles/${cycleId}/list${paramsToQuery(injectNullFlags(params))}`,
      transformResponse: (response) => ({ ...response, items: insertStagesActivityData(response.items) }),
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.CyclesStages, id: id })),
              { type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }]
    }),
    getActiveStagesForCycle: builder.query({
      query: (cycleId) => `invites/stages/cycles/${cycleId}/active`,
      transformResponse: (response) => ({ ...response, items: insertStagesActivityData(response.items) }),
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.CyclesStages, id: id })),
              { type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }]
    }),
    getStageDetail: builder.query({
      query: ({ stageId }) => `invites/stages/${stageId}`,
      transformResponse: (response) => insertSingleStageActivityData(response),
      providesTags: ({ id }, _, { stageId: stageIdParam, cycleId: cycleIdParam }) =>
        [{ type: ApiTags.CyclesStages, id: id ?? stageIdParam }, { type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }]
    })
  })
})

function insertStagesActivityData (stages) {
  console.debug('Inserting stage activity data', stages)
  return stages.map(stage => insertSingleStageActivityData(stage))
}

function insertSingleStageActivityData (stage) {
  const formattedStage = { ...stage }
  const stageStub = { id: stage.id, index: stage.index, assessment: { id: stage.assessment.id, name: stage.assessment.name } }
  if (stage.activity) {
    formattedStage.activity = stage.activity.map(activity => ({ ...activity, stage: stageStub }))
  }
  if (stage.admin_activity) {
    formattedStage.admin_activity = stage.admin_activity.map(activity => ({ ...activity, stage: stageStub }))
  }
  return formattedStage
}

export const { useGetStagesForCycleQuery, useGetActiveStagesForCycleQuery, useGetStageDetailQuery } = CycleStagesApi
