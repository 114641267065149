/* eslint react/prop-types: 0 */
import { Button, Group, Modal } from '@mantine/core'
import React, { useContext, useMemo, useState } from 'react'
import ReactTable, { ReactTableContextsProvider } from '../core/ReactTable/ReactTable'
import { useSkillbuilderCycleActions, useSkillbuilderCycleColumns, useSkillbuilderCycleFilters, useSkillbuilderCycleTableActions } from './SkillbuilderCyclesHooks'
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData, selectParamsLoaded } from '../core/ReactTable/paramsSlice';
import { useDeleteSkillbuilderCycleMutation, useGetSkillbuilderCyclesQuery } from './SkillbuilderApi';
import { NamespaceContext, TableDataContext } from '../core/ReactTable/TableContexts';
import { apiSlice } from '../api';
import { showNotification } from '@mantine/notifications';

const namespace = 'skillbuilderCycles'

const defaultFilters = { status: '0' }
const defaultHiddenColumns = []

export default function SkillbuilderCyclesTable () {
  const isAdmin = parseInt(document.getElementById('skillbuilder-cycles-container').getAttribute('data-is-admin')) === 1
  const [collection] = useLazySkillbuilderCyclesQuery(namespace)
  const [deleteSkillbuilderCycle] = useDeleteSkillbuilderCycleMutation()
  const [cycleToDelete, setCycleToDelete] = useState(null)

  const deleteCycle = () => { // TODO discuss - it used to be impossible to setCycleToDelete anything other than null. This endpoint no longer exists.
    deleteSkillbuilderCycle({ id: cycleToDelete })
      .unwrap()
      .then(() => {
        showNotification({
          message: 'Successfully deleted cycle',
          color: 'success'
        })
        setCycleToDelete(null)
      })
      .catch(() =>
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error deleting the cycle',
          color: 'red'
        })
      )
  }

  const handleRowClicked = row => {
    if (typeof row === 'undefined') return true
    const cycleId = row.dataset.rowid
    window.location.href = `/testenv/jobs/${cycleId}/view`
  }

  const findCycleById = (id) => {
    return collection?.items.filter(c => c.id === id)[0]
  }

  const filters = useSkillbuilderCycleFilters(isAdmin)
  const actions = useSkillbuilderCycleActions(isAdmin) // TODO pass setCycleToDelete if endpoint enabled.
  const tableActions = useSkillbuilderCycleTableActions(isAdmin)
  const columns = useSkillbuilderCycleColumns()

  return (
    <>
      <ReactTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <SkillbuilderCyclesTableDataProvider>
          <ReactTable
            actions={actions}
            tableActions={tableActions}
            tableActionsLabel='Cycle Actions'
            filters={filters}
            onRowClick={handleRowClicked}
            miw={800}
            searchable
          />
        </SkillbuilderCyclesTableDataProvider>
      </ReactTableContextsProvider>
      <Modal
        opened={!!cycleToDelete}
        onClose={() => { setCycleToDelete(null) }}
        title={cycleToDelete && `Are you sure you want to delete cycle: ${findCycleById(cycleToDelete)?.name ?? 'Name Missing'}?`}
      >
        <Group>
          <Button color='red' onClick={deleteCycle}>Delete</Button>
          <Button color='gray' onClick={() => setCycleToDelete(null)}>Cancel</Button>
        </Group>
      </Modal>
    </>
  )
}

export function useLazySkillbuilderCyclesQuery (namespace) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = apiSlice.endpoints.getSkillbuilderCycles.useQueryState(queryParams)
  return [collection ?? null, querying]
}

export function useSkillbuilderCyclesQuery (namespace, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetSkillbuilderCyclesQuery(queryParams, { skip })
  return [collection ?? null, querying]
}

export function SkillbuilderCyclesTableDataProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useSkillbuilderCyclesQuery(namespace, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating SkillbuilderCyclesTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Skillbuilder cycles table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
