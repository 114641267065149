/* eslint react/prop-types: 0 */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Header, NumberCell } from '../../../core/ReactTable/ReactTable';
import { Text, Tooltip } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { combineApplicantName, formatDateFromSource, renderDateFromSource } from './formatUtil';
import { CyclePassActivityType, isAssessmentStart } from '../../../../js/generated/enums/CyclePassActivityType';
import { isCompletion, isForwarded, isNonForwardedCompletion } from '../../../../js/generated/enums/StageProgressStatus';
import { stageProgressStatusIdToName } from './progresses/util';

/**
 * @returns {[?object, ?object, ?object, ?object]} [CyclePassActivity|null, CyclePassActivity|null, CyclePassActivity|null, ?metadataFromCallback]
 */
export function useParsedPassActivity (target, metadataCallback = null) {
  const targetActivity = target?.activity ?? BLANK_TABLE_ACTIONS
  const [firstStart, lastActivity, lastSubmit, metadata] = useMemo(() => {
    let startActivity = null
    let lastSubmitActivity = null
    let currentActivity = null
    const parsedMetadata = metadataCallback ? {} : null
    for (const activity of targetActivity) {
      if (isAssessmentStart(activity.type) && !startActivity) {
        startActivity = activity
      } else if (activity.type === CyclePassActivityType.SubmitAssessment) {
        lastSubmitActivity = activity
      }
      metadataCallback?.(parsedMetadata, activity)
      currentActivity = activity
    }
    return [startActivity, currentActivity, lastSubmitActivity, parsedMetadata]
  }, [targetActivity, metadataCallback])
  return [firstStart, lastActivity, lastSubmit, metadata]
}

export function useConfirmNavigate (confirm = false) {
  const confirmNavigate = useRef(!!confirm)

  useEffect(() => {
    confirmNavigate.current = !!confirm
  }, [confirm])

  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (confirmNavigate.current) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => window.removeEventListener('beforeunload', onBeforeUnload)
  }, [])

  return useCallback((confirm) => {
    confirmNavigate.current = !!confirm
  }, [])
}

export const BLANK_TABLE_ACTIONS = []

const TIMESTAMP_COLUMNS = [
  {
    Header: <Header>Created At</Header>,
    id: 'createdAt',
    accessor: 'created_at',
    Cell: ({ cell: { value } }) => {
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Created Date' position='top'>
              <Text>{dayjs(value).format('MM-DD-YYYY')}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: true,
    hideable: true
  },
  {
    Header: <Header>Updated At</Header>,
    id: 'updatedAt',
    accessor: 'updated_at',
    Cell: ({ cell: { value } }) => {
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Last Updated' position='top'>
              <Text>{dayjs(value).format('MM-DD-YYYY')}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: true,
    hideable: true
  }
]

const ACTIVITY_SEEN_COLUMNS = [
  {
    Header: <Header>Last Seen At</Header>,
    id: 'last_seen_at',
    accessor: 'last_seen_at',
    Cell: ({ cell: { value } }) => {
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Last Seen At' position='top'>
              <Text>{value ? renderDateFromSource(value, null, true) : ''}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true
  }
]

const SUBMISSION_TRACKING_COLUMNS = [
  {
    Header: <Header>First Assessment Start</Header>,
    id: 'first_assessment_start',
    accessor: 'activity',
    Cell: ({ cell: { value } }) => {
      const startTimestamps = !value?.length ? [] : value.filter(activity => isAssessmentStart(activity.type)).map(activity => formatDateFromSource(activity.created_at))
      const minStart = !startTimestamps?.length ? null : Math.min(...startTimestamps)
      return (
        <NumberCell centered>
          <span>
            <Tooltip label={minStart ? 'First Submission Started At' : 'No Submission Attempts Started'} position='top'>
              <Text>{renderDateFromSource(minStart, null, true)}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true
  },
  {
    Header: <Header>Last Assessment Start</Header>,
    id: 'last_assessment_start',
    accessor: 'activity',
    Cell: ({ cell: { value } }) => {
      const startTimestamps = !value?.length ? [] : value.filter(activity => isAssessmentStart(activity.type)).map(activity => formatDateFromSource(activity.created_at))
      const maxStart = !startTimestamps?.length ? null : Math.max(...startTimestamps)
      return (
        <NumberCell centered>
          <span>
            <Tooltip label={maxStart ? 'Last Submission Started At' : 'No Submission Attempts Started'} position='top'>
              <Text>{renderDateFromSource(maxStart, null, true)}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true,
    filterable: false
  },
  {
    Header: <Header>First Assessment Submit</Header>,
    id: 'first_assessment_submit',
    accessor: 'activity',
    Cell: ({ cell: { value } }) => {
      const submitTimestamps = !value?.length ? [] : value.filter(activity => activity.type === CyclePassActivityType.SubmitAssessment).map(activity => formatDateFromSource(activity.created_at))
      const minSubmit = !submitTimestamps.length ? null : Math.min(...submitTimestamps)
      return (
        <NumberCell centered>
          <span>
            <Tooltip label={minSubmit ? 'First Submission Completed At' : 'No Submissions Completed'} position='top'>
              <Text>{renderDateFromSource(minSubmit, null, true)}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true
  },
  {
    Header: <Header>Last Assessment Submit</Header>,
    id: 'last_assessment_submit',
    accessor: 'activity',
    Cell: ({ cell: { value } }) => {
      const submitTimestamps = !value?.length ? [] : value.filter(activity => activity.type === CyclePassActivityType.SubmitAssessment).map(activity => formatDateFromSource(activity.created_at))
      const maxSubmit = !submitTimestamps.length ? null : new Date(Math.max(...submitTimestamps))
      return (
        <NumberCell centered>
          <span>
            <Tooltip label={maxSubmit ? 'Last Submission Completed At' : 'No Submissions Completed'} position='top'>
              <Text>{renderDateFromSource(maxSubmit, null, true)}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true,
    filterable: false
  }
]

const RELATED_INVITES_COLUMNS = [
  {
    Header: <Header>Number Invites</Header>,
    id: 'number_invites',
    accessor: 'assessment_invites',
    Cell: ({ cell: { value } }) => {
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Click for Details' position='top'>
              <Text>{value?.length ?? 0}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true
  },
  {
    Header: <Header>Active Invites</Header>,
    id: 'number_active_invites',
    accessor: 'assessment_invites',
    Cell: ({ cell: { value } }) => {
      const openInviteCount = value?.filter(invite => !!invite.active).length ?? 0
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Click for Details' position='top'>
              <Text>{openInviteCount}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true
  },
  {
    Header: <Header>Inactive Invites</Header>,
    id: 'number_inactive_invites',
    accessor: 'assessment_invites',
    Cell: ({ cell: { value } }) => {
      const closedInviteCount = value?.filter(invite => !invite.active).length ?? 0
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Click for Details' position='top'>
              <Text>{closedInviteCount}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true
  },
  {
    Header: <Header>Locked Invites</Header>,
    id: 'number_locked_invites',
    accessor: 'assessment_invites',
    Cell: ({ cell: { value } }) => {
      const lockedInviteCount = value?.filter(invite => !!invite.locked).length ?? 0
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Click for Details' position='top'>
              <Text>{lockedInviteCount}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true
  }
]

const RELATED_APPLICANT_COLUMNS = [
  {
    Header: <Header>Applicant</Header>,
    id: 'applicant_name',
    accessor: 'applicant',
    Cell: ({ cell: { value, row } }) => {
      return (
        <Text>{combineApplicantName(value ?? row.original.cycle_pass?.applicant)}</Text>
      )
    },
    sortable: false,
    hideable: true,
    searchable: false,
    filterable: false
  }
]

const RELATED_EMAIL_COLUMNS = [
  {
    Header: <Header>Email</Header>,
    id: 'email_address',
    accessor: 'email',
    Cell: ({ cell: { value, row } }) => {
      const address = value?.id ?? row.original.cycle_pass?.applicant?.email_address ?? row.original.applicant?.email_address ?? ''
      return (
        <Text>{address}</Text>
      )
    },
    sortable: false,
    hideable: true,
    searchable: false,
    filterable: false
  }
]

const LOCKED_ONLY_COLUMNS = [
  {
    Header: <Header>Locked</Header>,
    id: 'is_locked',
    accessor: 'locked',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value ? 'Yes' : 'No'}</Text>
      )
    },
    sortable: false,
    filterable: true
  }
]

const TOGGLE_ACTIVE_COLUMNS = [
  {
    Header: <Header>Active</Header>,
    id: 'is_active',
    accessor: 'active',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value ? 'Active' : 'Inactive'}</Text>
      )
    },
    sortable: false,
    filterable: false
  },
  {
    Header: <Header>Locked</Header>,
    id: 'is_locked',
    accessor: 'locked',
    Cell: ({ cell: { value, row } }) => {
      const isActive = row.original.active
      return (
        <Text>{value ? 'Locked' : (isActive ? 'Open' : 'Dormant')}</Text>
      )
    },
    sortable: false,
    filterable: true
  }
]

const ID_COLUMNS = [
  {
    Header: <Header>ID</Header>,
    id: 'id',
    accessor: 'id',
    Cell: ({ cell: { value } }) => {
      return <Text ta='right'>{value}</Text>
    },
    sortable: true,
    hideable: true
  }
]

const RELATED_PASS_LOCKED_ONLY_COLUMNS = [
  {
    Header: <Header>Pass Locked</Header>,
    id: 'pass_locked',
    accessor: 'cycle_pass.locked',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value ? 'Yes' : 'No'}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

const RELATED_PASS_COLUMNS = [
  {
    Header: <Header>Pass Active</Header>,
    id: 'pass_active',
    accessor: 'cycle_pass.active',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value ? 'Active' : 'Inactive'}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Pass Locked</Header>,
    id: 'pass_locked',
    accessor: 'cycle_pass.locked',
    Cell: ({ cell: { value, row } }) => {
      const isActive = row.original.cycle_pass?.active
      return (
        <Text>{value ? 'Locked' : (isActive ? 'Open' : 'Dormant')}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

const RELATED_PROGRESSES_COLUMNS = [
  {
    Header: <Header>Original Stage Progresses</Header>,
    id: 'number_stage_progresses',
    accessor: 'stage_progresses',
    Cell: ({ cell: { value } }) => {
      const nonForwardedProgresses = value?.filter(progress => !isForwarded(progress.status)) ?? []
      const stageProgressesCount = nonForwardedProgresses.length
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Click for Details' position='top'>
              <Text>{stageProgressesCount}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Locked Original Progresses</Header>,
    id: 'number_locked_stage_progresses',
    accessor: 'stage_progresses',
    Cell: ({ cell: { value } }) => {
      const nonForwardedProgresses = value?.filter(progress => !isForwarded(progress.status)) ?? []
      const lockedStageProgressesCount = nonForwardedProgresses.filter(progress => !!progress.locked).length
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Click for Details' position='top'>
              <Text>{lockedStageProgressesCount}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Finished Original Progresses</Header>,
    id: 'number_finished_stage_progresses',
    accessor: 'stage_progresses',
    Cell: ({ cell: { value } }) => {
      const originalCompleteProgresses = value?.filter(progress => !!isNonForwardedCompletion(progress.status)) ?? []
      const finishedStageProgressesCount = originalCompleteProgresses.length
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Click for Details' position='top'>
              <Text>{finishedStageProgressesCount}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

const RELATED_ASSESSMENT_COLUMNS = [
  {
    Header: <Header>Number</Header>,
    id: 'index',
    accessor: 'index',
    Cell: ({ cell: { value } }) => {
      const number = (value ?? -2) + 1
      const numberDisplay = number > 0 ? number : 'Unknown'
      return (
        <Text>{numberDisplay}</Text>
      )
    },
    sortable: true,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Replaced</Header>,
    id: 'replaced',
    accessor: 'stage_replacement',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value ? renderDateFromSource(value.created_at) : ''}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Assessment</Header>,
    id: 'stage_assessment_name',
    accessor: 'assessment.name',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Assessment Internal Name</Header>,
    id: 'stage_assessment_internal_name',
    accessor: 'assessment',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value?.unpublished_assessment?.internal_name ?? ''}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

const STAGE_PROGRESS_STATUS_COLUMNS = [
  {
    Header: <Header>Status</Header>,
    id: 'status',
    accessor: 'status',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{stageProgressStatusIdToName[value] ?? 'Unknown'}</Text>
      )
    },
    sortable: false,
    hideable: true
  },
  {
    Header: <Header>Stage Number</Header>,
    id: 'index',
    accessor: 'stage',
    Cell: ({ cell: { value } }) => {
      const number = (value?.index ?? -2) + 1
      const numberDisplay = number > 0 ? number : 'Unknown'
      return (
        <Text>{numberDisplay}</Text>
      )
    },
    sortable: true,
    hideable: true
  },
  {
    Header: <Header>Assessment</Header>,
    id: 'stage_assessment',
    accessor: 'stage',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value?.assessment?.name ?? 'Unknown'}</Text>
      )
    },
    sortable: false,
    hideable: true
  },
  {
    Header: <Header>Forwarded</Header>,
    id: 'progress_forwarded',
    accessor: 'forward_to',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value ? renderDateFromSource(value.created_at) : ''}</Text>
      )
    },
    sortable: false,
    hideable: true
  },
  {
    Header: <Header>Original</Header>,
    id: 'progress_original',
    accessor: 'status',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{isForwarded(value) ? 'No' : 'Yes'}</Text>
      )
    },
    sortable: false,
    hideable: true
  },
  {
    Header: <Header>Completed</Header>,
    id: 'progress_complete',
    accessor: 'status',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{isCompletion(value) ? 'Yes' : 'No'}</Text>
      )
    },
    sortable: false,
    hideable: true
  }
]

const RELATED_OPEN_INVITEES_COLUMNS = [
  {
    Header: <Header>Unique Invite Opens</Header>,
    id: 'number_invitees',
    accessor: 'invitees',
    Cell: ({ cell: { value } }) => {
      return (
        <NumberCell centered>
          <span>
            <Tooltip label='Click for Details' position='top'>
              <Text>{value?.length ?? 0}</Text>
            </Tooltip>
          </span>
        </NumberCell>
      )
    },
    sortable: false,
    hideable: true
  }
]

const NAMED_ENTITY_COLUMNS = [
  {
    Header: <Header>Name</Header>,
    id: 'entity_name',
    accessor: 'name',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

const INVITE_CONFIG_COLUMNS = [
  {
    Header: <Header>Expiration Date</Header>,
    id: 'expirationDate',
    accessor: 'invite_config.expiration_date',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{renderDateFromSource(value)}</Text>
      )
    },
    sortable: true,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Description</Header>,
    id: 'description',
    accessor: 'invite_config.internal_note',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value ?? ''}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Link Prefix</Header>,
    id: 'link_prefix',
    accessor: 'invite_config.link_prefix',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value || ''}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Header</Header>,
    id: 'header',
    accessor: 'invite_config.header',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value || ''}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Message</Header>,
    id: 'message',
    accessor: 'invite_config.message',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value || ''}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

const PHASE_COLUMNS = [
  {
    Header: <Header>Phase</Header>,
    id: 'phase',
    accessor: 'config',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value?.phase?.name ?? ''}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

const INVITE_PHASE_COLUMNS = [
  {
    Header: <Header>Phase</Header>,
    id: 'phase',
    accessor: 'phase',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value?.name ?? ''}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Type</Header>,
    id: 'invite_type',
    accessor: 'type',
    Cell: ({ cell: { value } }) => {
      return (
        <Text>{value ?? ''}</Text>
      )
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

const TRUE_FALSE_FILTER_OPTIONS = Object.freeze([
  Object.freeze({ label: 'True', value: '1' }),
  Object.freeze({ label: 'False', value: '0' })
])

const TOGGLE_ACTIVE_FILTERS = [
  {
    id: 'locked',
    label: 'Locked',
    leftSection: <IconLock />,
    options: TRUE_FALSE_FILTER_OPTIONS
  }
]

export const PASS_COLUMNS = Object.freeze([
  ...ID_COLUMNS,
  ...TOGGLE_ACTIVE_COLUMNS,
  ...RELATED_EMAIL_COLUMNS,
  ...RELATED_APPLICANT_COLUMNS,
  ...RELATED_INVITES_COLUMNS,
  ...TIMESTAMP_COLUMNS,
  ...ACTIVITY_SEEN_COLUMNS,
  ...SUBMISSION_TRACKING_COLUMNS,
  ...RELATED_PROGRESSES_COLUMNS
])

export const DEFAULT_HIDDEN_PASS_COLUMNS = Object.freeze([
  'id',
  'updatedAt',
  'first_assessment_start',
  'first_assessment_submit',
  'number_active_invites',
  'number_inactive_invites',
  'number_locked_invites',
  'number_locked_stage_progresses'
])

export const PASS_FILTERS = Object.freeze([
  ...TOGGLE_ACTIVE_FILTERS
])

export const DEFAULT_PASS_FILTERS = Object.freeze({
  locked: '0'
})

export const INVITE_COLUMNS = Object.freeze([
  ...ID_COLUMNS,
  ...TOGGLE_ACTIVE_COLUMNS,
  ...RELATED_EMAIL_COLUMNS,
  ...RELATED_APPLICANT_COLUMNS,
  ...TIMESTAMP_COLUMNS,
  ...ACTIVITY_SEEN_COLUMNS,
  ...RELATED_PASS_COLUMNS,
  ...INVITE_PHASE_COLUMNS,
  ...INVITE_CONFIG_COLUMNS
])

export const DEFAULT_HIDDEN_INVITE_COLUMNS = Object.freeze([
  'id',
  'pass_active',
  'pass_locked',
  'link_prefix',
  'header',
  'message'
])

export const INVITE_FILTERS = Object.freeze([
  ...TOGGLE_ACTIVE_FILTERS
])

export const DEFAULT_INVITE_FILTERS = Object.freeze({
  locked: '0'
})

export const STAGE_PROGRESS_COLUMNS = Object.freeze([
  ...ID_COLUMNS,
  ...LOCKED_ONLY_COLUMNS,
  ...TIMESTAMP_COLUMNS,
  ...STAGE_PROGRESS_STATUS_COLUMNS,
  ...RELATED_PASS_LOCKED_ONLY_COLUMNS
])

export const DEFAULT_HIDDEN_STAGE_PROGRESS_COLUMNS = Object.freeze([
  'id', 'pass_locked', 'progress_forwarded', 'progress_original'
])

export const STAGE_PROGRESS_FILTERS = Object.freeze([
  ...TOGGLE_ACTIVE_FILTERS
])

export const DEFAULT_STAGE_PROGRESS_FILTERS = Object.freeze({})

export const CYCLE_STAGE_COLUMNS = Object.freeze([
  ...ID_COLUMNS,
  ...LOCKED_ONLY_COLUMNS,
  ...TIMESTAMP_COLUMNS,
  ...PHASE_COLUMNS,
  ...RELATED_ASSESSMENT_COLUMNS,
  ...RELATED_PROGRESSES_COLUMNS,
  ...SUBMISSION_TRACKING_COLUMNS
])

export const DEFAULT_HIDDEN_CYCLE_STAGE_COLUMNS = Object.freeze([
  'id',
  'first_assessment_start',
  'first_assessment_submit',
  'number_locked_stage_progresses'
])

export const CYCLE_STAGE_FILTERS = Object.freeze([
  ...TOGGLE_ACTIVE_FILTERS
])

export const DEFAULT_CYCLE_STAGE_FILTERS = Object.freeze({
  locked: '0'
})

export const OPEN_INVITE_COLUMNS = Object.freeze([
  ...ID_COLUMNS,
  ...NAMED_ENTITY_COLUMNS,
  ...INVITE_CONFIG_COLUMNS,
  ...TOGGLE_ACTIVE_COLUMNS,
  ...TIMESTAMP_COLUMNS,
  ...RELATED_OPEN_INVITEES_COLUMNS
])

export const DEFAULT_HIDDEN_OPEN_INVITE_COLUMNS = Object.freeze([
  'id',
  'link_prefix',
  'header',
  'message'
])

export const OPEN_INVITE_FILTERS = Object.freeze([
  ...TOGGLE_ACTIVE_FILTERS
])

export const DEFAULT_OPEN_INVITE_FILTERS = Object.freeze({
  locked: '0'
})
