import { useGetProgressDetailQuery, useGetProgressesForCycleQuery } from './StageProgressesApi';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../../../core/ReactTable/paramsSlice';
import { apiSlice } from '../../../../api';
import {
  BLANK_TABLE_ACTIONS,
  DEFAULT_HIDDEN_STAGE_PROGRESS_COLUMNS,
  DEFAULT_STAGE_PROGRESS_FILTERS,
  STAGE_PROGRESS_COLUMNS,
  STAGE_PROGRESS_FILTERS
} from '../CycleInviteHooks';

export function useProgressDetail (progressId, cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetProgressDetailQuery({ progressId, cycleId }, { skip })
  return [data ?? null, querying, loading]
}

export function useStageProgresses (namespace, cycleId, stage, pass, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetProgressesForCycleQuery({ stage, pass, ...queryParams, cycleId }, { skip })
  return [collection ?? null, querying]
}

export function useLazyStageProgresses (namespace, cycleId, stage, pass) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = apiSlice.endpoints.getProgressesForCycle.useQueryState({ stage, pass, ...queryParams, cycleId })
  return [collection ?? null, querying]
}

export function useStageProgressesTable (stageId, passId) {
  // TODO don't return (CyclePass&Applicant&Email)/Stage as column nor filter option if cyclePassId/stageId provided by route.
  return {
    defaultHiddenColumns: DEFAULT_HIDDEN_STAGE_PROGRESS_COLUMNS,
    defaultFilters: DEFAULT_STAGE_PROGRESS_FILTERS,
    columns: STAGE_PROGRESS_COLUMNS,
    filters: STAGE_PROGRESS_FILTERS,
    actions: BLANK_TABLE_ACTIONS,
    searchable: true
  }
}
