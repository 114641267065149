import React, { useState, useEffect, useMemo } from 'react'
import { notifications, showNotification } from '@mantine/notifications'
import { useDisclosure } from '@mantine/hooks';
import {
  Modal,
  Space,
  Text,
  Button,
  Group,
  Select,
  Stack,
  Paper,
  Title,
  LoadingOverlay,
  Box, rem
} from '@mantine/core';
import {
  IconArrowNarrowDown,
  IconArrowNarrowUp, IconCheck,
  IconExclamationMark,
  IconMailFast
} from '@tabler/icons-react'
import { getCancellableTemplate } from '../../../../js/api/email_template_repository';
import { previewTemplate } from '../../../../js/api/applicant_repository';
import * as DOMPurify from 'dompurify';
import { useBoostTemplates } from './BoostHooks';
import PropTypes from 'prop-types';
import { useSendBoostBulkInvitesMutation } from './BoostApi';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllSelectedValues, unselectAll } from '../../../core/ReactTable/selectedColumnsSlice';

function getBoostTemplates (templates) {
  return templates
    .filter((template) => template.category?.tag === 'boost-invite')
    .sort((a, b) => (a.default ? 0 : 1) - (b.default ? 0 : 1))
    .map((template) => ({ value: template.id.toString(), label: template.title }))
}

export default function BoostBulkInviteModal ({ cycle, opened, close }) {
  const [sendInvites, { isLoading: processing }] = useSendBoostBulkInvitesMutation()
  const [template, setTemplate] = useState(null)
  const templates = useBoostTemplates(cycle)
  const dispatch = useDispatch()
  const selectedApplicants = useSelector(state => selectAllSelectedValues(state))

  const filteredTemplates = useMemo(() => getBoostTemplates(templates), [templates])
  useEffect(() => {
    if (filteredTemplates.length) {
      setTemplate(filteredTemplates[0]?.value ?? null)
    }
  }, [filteredTemplates])

  const submit = () => {
    // Close modal and disable the send button until the emails have been processed
    close()

    const id = notifications.show({
      loading: true,
      title: 'Sending Invitations',
      message: 'The bulk invitations are currently processing',
      autoClose: false,
      withCloseButton: false
    });

    sendInvites({
      id: cycle,
      template: template,
      applicants: selectedApplicants
    })
      .unwrap()
      .then((response) => {
        console.debug('Success sending bulk invites: ', response)
        dispatch(unselectAll())

        notifications.update({
          id: id,
          title: 'Bulk Invitations',
          message: response.message,
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          color: 'blue',
          autoClose: 5000,
          withCloseButton: true,
          loading: false
        });
      })
      .catch((err) => {
        console.error('Error sending bulk invites:', err)

        switch (err.status) {
          case 400:
            notifications.update({
              id: id,
              title: 'Something went wrong',
              message: err.data.errors.join(),
              icon: <IconExclamationMark style={{ width: rem(18), height: rem(18) }}/>,
              color: 'red',
              autoClose: 7000,
              withCloseButton: true,
              loading: false
            })
            break
          case 401:
            notifications.update({
              id: id,
              title: 'Limit Reached',
              message: err.data.errors.join(),
              icon: <IconExclamationMark style={{ width: rem(18), height: rem(18) }}/>,
              color: 'yellow',
              autoClose: 7000,
              withCloseButton: true,
              loading: false
            })
            break
          default:
            notifications.update({
              id: id,
              title: 'Something went wrong',
              message: 'There was an error sending the bulk invitations',
              icon: <IconExclamationMark style={{ width: rem(18), height: rem(18) }}/>,
              color: 'red',
              autoClose: 5000,
              withCloseButton: true,
              loading: false
            })
        }
      })
  }

  return (
    <Modal.Root opened={opened} onClose={close}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header padding={0} bg={'blue'}>
          <Group>
            <IconMailFast color={'white'} size='5rem'/>
            <Text c='white' size='2.5rem' fw={700}>Bulk Invite</Text>
          </Group>
        </Modal.Header>

        <Modal.Body>
          <Space h="xs" />
          <Stack cols={1}>
            {
              selectedApplicants.length === 0
                ? <Text>No candidates have been selected. Use the checkboxes to start adding.</Text>
                : <Text>Candidates Selected: {selectedApplicants.length}</Text>
            }
            <Select
              label='Email Template'
              placeholder='Email template'
              data={filteredTemplates}
              defaultValue={template}
              onChange={(value) => setTemplate(value)}
            />
            {(selectedApplicants.length > 0 && template) &&
              <PreviewTemplate template={template} applicant={selectedApplicants[0]} cycle={cycle}/>
            }
          </Stack>
          <Space h="xs" />
          <Space h="xs" />
          <Space h="xs" />
          <Group justify="flex-end">
            <Button onClick={close} color='gray' >Cancel</Button>
            <Button
              type='submit'
              color='blue'
              disabled={selectedApplicants.length === 0 || processing}
              onClick={() => submit()}
            >
              Send Emails
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}

function PreviewTemplate ({ template, applicant, cycle }) {
  const [opened, handlers] = useDisclosure(null)
  const [preview, setPreview] = useState(null)

  useEffect(() => {
    setPreview(null)
  }, [template])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal

    getCancellableTemplate(template, signal)
      .then((response) => {
        previewTemplate(applicant, { subject: response.subject, body: response.template, cycle: cycle })
          .then(response => setPreview(response))
      })
      .catch((err) => {
        console.error('Error previewing template:', err.response)

        showNotification({
          title: 'Something went wrong',
          message: 'There was an error previewing the template',
          color: 'red',
          autoClose: 3000
        })
      })

    return () => {
      controller.abort();
    }
  }, [template, applicant, cycle])

  return (
    <>
      <Button
        onClick={() => handlers.toggle()} rightSection={opened ? <IconArrowNarrowUp/> : <IconArrowNarrowDown/>}
        aria-label={!opened ? 'Open Email Preview' : 'Hide Email Preview'}
      >
        Preview Email
      </Button>
      {opened &&
        <Box pos="relative">
          <LoadingOverlay visible={preview === null} overlayProps={{ radius: 'sm', blur: 2 }}/>
          <Paper shadow="sm" radius="md" p="xl">
            {preview &&
              <>
                <Title order={4}>Subject</Title>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(preview.subject) }}></div>
                <Title order={4}>Body</Title>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(preview.body) }}></div>
              </>
            }
          </Paper>
        </Box>
      }
    </>
  )
}

BoostBulkInviteModal.propTypes = {
  cycle: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

PreviewTemplate.propTypes = {
  template: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  applicant: PropTypes.number.isRequired,
  cycle: PropTypes.string.isRequired
}
