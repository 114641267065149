import { SectionPosition } from '../../js/generated/enums/SectionPosition';
import { questionMediaLocationFromId } from '../../js/generated/enums/QuestionMediaLocation';
import { isAnswerable, isNumerable, QuestionType } from '../../js/generated/enums/QuestionType';
import { mediaTypeFromId } from '../../js/generated/enums/MediaType';
import { LogicFeature } from '../../js/generated/enums/LogicFeature';
import { evaluateNodeInnerLogic, getCurrentSeconds } from './util';

/**
 * @typedef {object} SourceRespondentAnswerJson
 * @property {?SourcePublishedAnswerJson} answer?
 * @property {?string} additional_answer?
 * @property {?string} additional_comment?
 */

/**
 * @typedef {object} SourcePublishedQuestionCompetencyJson
 * @property {int} id
 * @property {string} name
 */

/**
 * @typedef {object} PublishedQuestionConditionalLogicJson
 * @property {int} id
 * @property {int} question_id Data question id
 * @property {LogicOperator} operator
 * @property {string} value
 * @property {LogicFeature} feature
 * @property {?int|undefined} parent_question_id?
 * @property {?int|undefined} parent_id?
 * @property {PublishedQuestionConditionalLogicJson[]} children child logic
 */

/**
 * @typedef {object} SourcePublishedAnswerJson
 * @property {int} id
 * @property {string} content
 * @property {?bool} correct?
 * @property {?string} score?
 */

/**
 * @typedef {object} SourcePublishedQuestionMedia
 * @property {int} id
 * @property {?int} height?
 * @property {?int} width?
 * @property {int} type typeId
 * @property {string} link
 */

/**
 * @typedef {object} SourcePublishedQuestionJson
 * @property {int} id
 * @property {string} content
 * @property {?int} number?
 * @property {int} vertical
 * @property {int} comment
 * @property {int} top
 * @property {int} is_dropdown
 * @property {?int} is_scored?
 * @property {boolean|undefined} show_correct_answer?
 * @property {QuestionType} type
 * @property {?string} display_content?
 * @property {SourcePublishedAnswerJson[]|undefined} display_answers?
 * @property {SourcePublishedAnswerJson[]|undefined} answers?
 * @property {?SourceRespondentAnswerJson} display_respondent_answer?
 * @property {?SourcePublishedQuestionMedia} media?
 * @property {SourcePublishedQuestionCompetencyJson[]|null} competencies?
 */

/**
 * @typedef {object} SourceSectionJson
 * @property {SourcePublishedQuestionJson[]} questions
 */

/**
 * @typedef {object} SourceBlockJson
 * @property {number} number
 * @property {?SourceSectionJson} center_section
 * @property {?SourceSectionJson} left_section
 * @property {?SourceSectionJson} right_section
 */

/**
 * @typedef {object} SourcePageJson
 * @property {SourceBlockJson[]} blocks
 * @property {int[]} question_ids
 */

/**
 * @typedef {object} PublishedAssessmentRespondentAnswer
 * @property {int} id questionId
 * @property {?string} answerId
 * @property {string[]} answerIds
 * @property {string} additionalAnswer
 * @property {string} additionalComment
 * @property {boolean} answered
 * @property {boolean} skipped
 * @property {boolean} declined
 * @property {boolean} shouldAnswer
 * @property {int} lastSyncId
 * @property {int} lastModificationId
 * @property {string} pageId
 * @property {int} pageIndex
 * @property {string} assessmentId
 * @property {int} indexInAssessment
 */

/**
 * @typedef {object} PublishedQuestionCompetency
 * @property {int} id
 * @property {string} name
 */

/**
 * @typedef {object} PublishedAnswer
 * @property {int} id
 * @property {int} index
 * @property {string} content
 * @property {?bool} correct
 * @property {?string} score
 */

/**
 * @typedef {object} PublishedQuestionMedia
 * @property {int} id
 * @property {?int} height
 * @property {?int} width
 * @property {int} typeId
 * @property {string} type
 * @property {string} link
 */

/**
 * @typedef {object} PublishedQuestionLocator
 * @property {int} questionId
 * @property {SectionPosition} blockSectionPosition
 * @property {int} indexInSection
 * @property {string} blockId
 * @property {int} indexInBlock
 * @property {string} pageId
 * @property {string} assessmentId
 * @property {int} pageIndex
 * @property {int} indexInPage
 * @property {int} indexInAssessment
 */

/**
 * @typedef {object} PublishedQuestionConditionalLogic
 * @property {int} id
 * @property {int} questionId Data question id
 * @property {LogicOperator} operator
 * @property {string} value
 * @property {LogicFeature} feature
 * @property {?int} parentQuestionId
 * @property {?int} parentId
 * @property {int} rootParentQuestionId
 * @property {int[]} children child logic ids
 */

/**
 * @typedef {object} PublishedQuestion
 * @property {int} id
 * @property {string} content question.display_content ?? question.content ?? ''
 * @property {boolean} showNumber
 * @property {?int} number
 * @property {boolean} vertical
 * @property {boolean} comment
 * @property {boolean} isDropdown
 * @property {QuestionMediaLocation} mediaLocation
 * @property {boolean} large True if Center section
 * @property {QuestionType} type
 * @property {PublishedAnswer[]} answers
 * @property {?PublishedQuestionMedia} media
 * @property {boolean} loadMedia TODO [optimization] can be implemented to load media before a question is shown, if necessary: chain loadMedia before previous question answered.
 * @property {PublishedQuestionCompetency[]|null} competencies
 * @property {PublishedQuestionLocator} location
 * @property {boolean} showCorrectAnswer
 * @property {boolean} answerable
 * @property {boolean} answerRequired
 * @property {boolean} show
 * @property {boolean} hideFromLogic
 * @property {int|null} previousQuestionId
 * @property {boolean} previousQuestionAnswerable
 * @property {boolean} previousQuestionOnPageIsContentOnly True if any previous question on page is content-only.
 * @property {int[]} logic  logic ids
 * @property {boolean} showHasLogicIndicator used in print to pdf
 */

/**
 * @typedef {object} PublishedAssessmentPageSection
 * @property {boolean} isFinalPageFinalBlockSection Only true for sections of the last block of the last loaded page.
 * @property {SectionPosition} position
 * @property {int[]} questionIds
 * @property {boolean} large True if center, else false.
 * @property {int} blockId
 * @property {int} pageIndex
 */

/**
 * @typedef {object} PublishedAssessmentBlock
 * @property {string} id
 * @property {string} pageId
 * @property {string} assessmentId
 * @property {int} pageIndex
 * @property {int} indexInPage
 * @property {Object.<SectionPosition, PublishedAssessmentPageSection>} sections  SectionPosition: PublishedAssessmentPageSection
 * @property {int[]} questionIds
 * @property {boolean} hasCenter
 * @property {boolean} show
 * @property {boolean} isFinalPageFinalBlock Only true for last block of the last loaded page.
 */

/**
 * @typedef {object} PublishedAssessmentPage
 * @property {string} id deterministic id set during initial state parsing, could cause issues down the road if paginated / non-paginated versions of the same assessment are used by the same applicant at the same time once we move to OPA due to index being used in the id.
 * @property {number} index
 * @property {string} assessmentId
 * @property {string[]} blockIds
 * @property {int[]} questionIds
 * @property {boolean} isFinalPage
 * @property {boolean} autoSubmit
 * @property {boolean} submittedOnce
 * @property {boolean} submittedSinceLoad
 * @property {boolean} complete
 * @property {boolean} show
 */

/**
 * @typedef {object} PublishedAssessmentFocus
 * @property {?int} transitionToQuestionId
 * @property {?int} lastAnsweredQuestionId
 * @property {?int} promptTransitionQuestionId
 */

/**
 * @typedef {object} PublishedAssessment
 * @property {string} id
 * @property {string[]} pageIds
 * @property {int[]} questionIds
 * @property {?string} updateUrl
 * @property {string} submitUrl
 * @property {PublishedAssessmentFocus} focus
 * @property {?int} timeLimit
 * @property {number} startTime
 * @property {boolean} allowDeclineAnswers
 * @property {object} auth  // Facilitator Key | Assessment-PW | empty object
 * // @property {boolean} knockout  TODO [knockout questions] implement with asana task
 */

/**
 * @typedef {object} InitialAssessmentState
 * @property {PublishedQuestion[]} questions
 * @property {PublishedAssessmentRespondentAnswer[]} respondentAnswers
 * @property {PublishedQuestionConditionalLogic[]} logic
 * @property {PublishedAssessmentPage[]} pages
 * @property {PublishedAssessmentBlock[]} blocks
 * @property {PublishedAssessment} assessment
 */

/**
 * @param {SourcePageJson[]|null} pagesJson
 * @param {Object.<string, PublishedQuestionConditionalLogicJson[]>} logics
 * @param {number} startPageIndex
 * @param {?string} updateUrl
 * @param {?string} submitUrl
 * @param {?boolean} displayNumbers
 * @param {?int} timeLimit
 * @param {string} assessmentId
 * @param {?object} auth  // Facilitator Key | Assessment-PW | null
 * @param {?object} pdfConfig
 * @param {?boolean} pdfConfig?.displayCompetencies?
 * @param {?boolean} pdfConfig?.displayCorrectAnswers?
 * @param {?boolean} pdfConfig?.displayLogicIndicators?
 * @param {?boolean} pdfConfig?.displayAnswerScores?
 * @returns {InitialAssessmentState}
 */
export function reduxFormatPagesFromJson (pagesJson, logics, startPageIndex, updateUrl, submitUrl, timeLimit, displayNumbers, assessmentId, auth, pdfConfig = null) {
  const displayNumbersActive = (isAnyLogic(logics) && !pdfConfig) ? false : displayNumbers
  const newData = formatPagesFromJson(pagesJson, startPageIndex, displayNumbersActive, assessmentId, pdfConfig)
  const logicMap = parseLogicToInitialState(newData, logics)
  correctStartQuestionForLogic(newData)
  const questions = [...newData.questions.values()]
  const questionIds = questions.map(question => question.id)
  const pageIds = newData.pages.map(page => page.id)
  const respondentAnswers = questions.map(question => question.respondentAnswer)
  for (const question of questions) {
    delete question.respondentAnswer
  }
  return {
    pages: newData.pages,
    blocks: newData.blocks,
    questions: questions,
    respondentAnswers: respondentAnswers,
    assessment: { id: assessmentId, pageIds: pageIds, questionIds: questionIds, updateUrl: updateUrl, submitUrl: submitUrl ?? '', auth: auth ?? {}, focus: newData.focus, timeLimit: timeLimit, startTime: getCurrentSeconds(), allowDeclineAnswers: false },
    logic: [...logicMap.values()]
  }
}

function isAnyLogic (logics) {
  for (const logic of Object.values(logics)) {
    if (logic?.length) {
      console.debug('Found instance of logic - disabling display numbers if set.', logic, logics)
      return true
    }
  }
  return false
}

/**
 *
 * @param {Object} newData Note: Parsed Questions Data modified by this function. (fields: logic, hideFromLogic, respondentAnswer.answered & associated recorded values.)
 * @param {Object.<string, PublishedQuestionConditionalLogicJson[]>} logics String Question Id: LogicJson[]
 * @returns {Map<int, PublishedQuestionConditionalLogic[]>}
 */
function parseLogicToInitialState (newData, logics) {
  const logicMap = new Map()
  const hasLogics = Object.keys(logics ?? {})?.length
  const questionIdsInOrder = [...newData.questions.values()].sort((a, b) => a.location.indexInAssessment - b.location.indexInAssessment).map(elem => elem.id)
  for (const intQuestionId of questionIdsInOrder) {
    const question = newData.questions.get(intQuestionId)
    const logic = logics[intQuestionId.toString()] ?? []
    if (question && logic?.length) {
      const getLogicChildren = (node, fromParent = {}) => {
        let anyChildResult = false
        if (node.children?.length) {
          for (const child of node.children) {
            const childResult = getLogicChildren(child)
            anyChildResult = anyChildResult || childResult
          }
        } else {
          anyChildResult = true
        }
        const formattedNode = {
          ...fromParent,
          id: node.id,
          questionId: node.question_id,
          operator: node.operator,
          value: node.value,
          feature: node.feature,
          children: node.children?.map(child => child.id) ?? [],
          rootParentQuestionId: intQuestionId
        }
        logicMap.set(node.id, formattedNode)
        const dataQuestion = newData.questions.get(formattedNode.questionId) ?? {}
        const dataAnswer = dataQuestion?.respondentAnswer ?? {}
        if (!dataAnswer.id || !dataQuestion.id) {
          console.error('Unable to find data question with id matching expected logic id.', formattedNode.questionId, formattedNode, dataQuestion, newData.questions, node)
          return false
        }
        if (!anyChildResult) {
          return false
        }
        return evaluateNodeInnerLogic(node, dataQuestion, dataAnswer)
      }
      const featureMap = new Map()
      const rootLogic = []
      for (const currentLogic of logic) {
        const currentLogicResult = getLogicChildren(currentLogic, { parentQuestionId: intQuestionId })
        rootLogic.push(currentLogic.id)
        featureMap.set(currentLogic.feature, featureMap.get(currentLogic.feature) ? true : currentLogicResult)
      }
      question.logic = rootLogic
      let showQuestion = null
      let hideQuestion = null
      for (const [feature, result] of featureMap.entries()) {
        if (feature === LogicFeature.Hide) {
          hideQuestion = result
        } else if (feature === LogicFeature.Show) {
          showQuestion = result
        }
      }
      question.hideFromLogic = (showQuestion === null) ? !!hideQuestion : !showQuestion
      if (question.hideFromLogic) {
        question.respondentAnswer.answerId = null
        question.respondentAnswer.answerIds = []
        question.respondentAnswer.additionalAnswer = ''
        if (question.answerable) {
          question.respondentAnswer.answered = false
        }
      }
      console.debug('Set question initial hide from logic state.', question.id, question, question.respondentAnswer.answered, question.respondentAnswer)
    } else {
      if (hasLogics) {
        console.debug('No logic or question for question id', intQuestionId, logic.length, logic, question)
      }
    }
  }
  return logicMap
}

/**
 * @param {Object} newData Note: modified by function.
 * @returns {Object} modified newData
 */
function correctStartQuestionForLogic (newData) {
  const activeQuestionId = newData.focus.transitionToQuestionId
  if (activeQuestionId) {
    const activeQuestion = newData.questions.get(activeQuestionId)
    if (activeQuestion && activeQuestion.hideFromLogic) {
      console.info('Would have focused hidden question - skipping ahead.', activeQuestionId, activeQuestion.hideFromLogic, activeQuestion)
      const nextActiveQuestionOptions = [...newData.questions.values()].filter(elem => elem.location.indexInAssessment > activeQuestion.location.indexInAssessment).sort((a, b) => a.location.indexInAssessment - b.location.indexInAssessment).map(elem => elem.id)
      let lastProcessedOption = null
      let lastBlockId = null
      let lastPageId = null

      for (const nextActiveQuestionId of nextActiveQuestionOptions) {
        const nextActiveQuestion = newData.questions.get(nextActiveQuestionId)
        nextActiveQuestion.show = true
        if (nextActiveQuestion.location.blockId !== lastBlockId) {
          lastBlockId = nextActiveQuestion.location.blockId
          const nextBlock = newData.blocks.filter(block => block.id === lastBlockId)[0] ?? null
          if (nextBlock) {
            nextBlock.show = true
          } else {
            console.error('Could not find matching block for question location.', nextBlock, lastBlockId, nextActiveQuestion, newData.blocks)
          }
        }
        if (nextActiveQuestion.location.pageId !== lastPageId) {
          lastPageId = nextActiveQuestion.location.pageId
          const nextPage = newData.pages.filter(page => page.id === lastPageId)[0] ?? null
          if (nextPage) {
            nextPage.show = true
          } else {
            console.error('Could not find matching block for question location.', nextPage, lastPageId, nextActiveQuestion, newData.pages)
          }
        }
        if (!nextActiveQuestion.hideFromLogic && !nextActiveQuestion.respondentAnswer.answered && nextActiveQuestion.respondentAnswer.shouldAnswer) {
          lastProcessedOption = nextActiveQuestion
          break
        }
      }
      if (lastProcessedOption) {
        newData.focus.transitionToQuestionId = lastProcessedOption?.id ?? null
      } else {
        const previousActiveQuestionOptions = [...newData.questions.values()].filter(elem => elem.location.indexInAssessment < activeQuestion.location.indexInAssessment).sort((a, b) => b.location.indexInAssessment - a.location.indexInAssessment).map(elem => elem.id)
        for (const previousActiveQuestionId of previousActiveQuestionOptions) {
          const previousActiveQuestion = newData.questions.get(previousActiveQuestionId)
          if (!previousActiveQuestion.hideFromLogic) {
            lastProcessedOption = previousActiveQuestion
            break
          }
        }
        if (!lastProcessedOption) {
          console.warn('No suitable transition to target replacement for question.', activeQuestionId)
        }
        newData.focus.transitionToQuestionId = lastProcessedOption?.id ?? null
      }
      console.info('Replaced transition to question.', activeQuestionId, newData.focus.transitionToQuestionId)
    } else {
      console.debug('Active question missing or not hidden.', activeQuestionId, activeQuestion?.hideFromLogic, activeQuestion)
    }
  } else {
    console.debug('No focused question at start of assessment.', activeQuestionId, newData.focus)
  }
  return newData
}

function formatPagesFromJson (pagesJson, startPageIndex, displayNumbers, assessmentId = '', pdfConfig = null) {
  const pages = []
  const blocks = []
  const questions = new Map()
  let currentPageIndex = 0
  if ((pagesJson?.[0] ?? null)?.id) {
    console.warn('Pages now have id from source - no need to generate Id.', pagesJson)
  }
  console.info('Initial published assessment state parsing.', pagesJson, startPageIndex, displayNumbers)
  const lastPageIndex = (pagesJson?.length ?? 1) - 1
  const newStartPageIndex = (startPageIndex || startPageIndex === 0) ? (startPageIndex > lastPageIndex ? lastPageIndex : startPageIndex) : 0
  let previousPagesQuestionTotal = 0
  let numberQuestionsCannotBeAnswered = 0
  let answeredQuestionCount = 0
  let previousQuestion = null
  let lastShownQuestionId = null
  for (const page of pagesJson) {
    let anyPageQuestionsShown = false
    let previousQuestionOnPageIsContentOnly = false
    const isFinalPage = currentPageIndex === lastPageIndex
    const uniquePageQuestionIds = [...(new Set(page.question_ids)).values()]
    const pageIdIndexLookupMap = Object.fromEntries(
      uniquePageQuestionIds.map((questionId, index) => [questionId, index])
    )
    const pageId = assessmentId + '.' + currentPageIndex.toString() + '.' + (uniquePageQuestionIds.length ? uniquePageQuestionIds.slice(0, 2).join('-') : 'blank')
    const parsedPage = {
      id: pageId,
      index: currentPageIndex,
      assessmentId: assessmentId,
      blockIds: [],
      questionIds: [],
      isFinalPage: isFinalPage,
      autoSubmit: !isFinalPage,
      submittedOnce: currentPageIndex > newStartPageIndex,
      submittedSinceLoad: false,
      show: false // currentPageIndex === newStartPageIndex - overwritten if question shown
    }
    let currentBlockIndex = 0
    const lastBlockIndex = page.blocks.length - 1
    let previousBlocksQuestionsTotal = 0
    for (const block of (page.blocks)) {
      let anyBlockQuestionsShown = false
      const parsedBlock = {
        id: pageId + '.' + block.number.toString() + '.' + currentBlockIndex.toString(), // Adding currentBlockIndex prevents block numbers being attached to out of sequence questions from breaking the assessment. This could cause issues if all pages are no longer served up either together or one by one.
        pageId: pageId,
        assessmentId: assessmentId,
        pageIndex: currentPageIndex,
        indexInPage: currentBlockIndex,
        sections: {},
        questionIds: [],
        show: false, // (currentPageIndex === newStartPageIndex) && (currentBlockIndex === 0), - overwritten if question shown
        isFinalPageFinalBlock: isFinalPage && (currentBlockIndex === lastBlockIndex),
        hasCenter: !!block.center_section
      }
      if (block.center_section) {
        parsedBlock.sections[SectionPosition.Center] = parseSectionToBlock(
          parsedBlock,
          block.center_section,
          SectionPosition.Center
        )
        let currentSectionQuestionIndex = 0
        for (const question of block.center_section.questions) {
          const parsedQuestion = parseQuestion(
            pageIdIndexLookupMap,
            previousPagesQuestionTotal,
            previousBlocksQuestionsTotal,
            currentSectionQuestionIndex,
            parsedBlock,
            question,
            numberQuestionsCannotBeAnswered + answeredQuestionCount,
            displayNumbers,
            SectionPosition.Center,
            pdfConfig
          )
          parsedQuestion.previousQuestionId = previousQuestion?.id ?? null
          parsedQuestion.previousQuestionOnPageIsContentOnly = previousQuestionOnPageIsContentOnly
          parsedQuestion.previousQuestionAnswerable = previousQuestion ? previousQuestion.answerable : false
          if (!parsedQuestion.answerable) {
            numberQuestionsCannotBeAnswered += 1
          } else if (parsedQuestion.respondentAnswer.answered) {
            answeredQuestionCount += 1
          }
          previousQuestionOnPageIsContentOnly = previousQuestionOnPageIsContentOnly || ((parsedQuestion.type === QuestionType.SectionHeader) || (parsedQuestion.type === QuestionType.TextBlock))
          currentSectionQuestionIndex += 1
          previousQuestion = parsedQuestion
          questions.set(question.id, parsedQuestion)
          parsedBlock.questionIds.push(question.id)
          parsedPage.questionIds.push(question.id)
          parsedBlock.sections[SectionPosition.Center].questionIds.push(question.id)
          if (parsedQuestion.show) {
            lastShownQuestionId = parsedQuestion.id
            anyPageQuestionsShown = true
            anyBlockQuestionsShown = true
          }
        }
        previousBlocksQuestionsTotal += currentSectionQuestionIndex
      } else {
        parsedBlock.sections[SectionPosition.Left] = parseSectionToBlock(
          parsedBlock,
          block.left_section,
          SectionPosition.Left
        )
        parsedBlock.sections[SectionPosition.Right] = parseSectionToBlock(
          parsedBlock,
          block.right_section,
          SectionPosition.Right
        )

        const parsedLeftRightSectionQuestions = []
        let currentLeftSectionQuestionIndex = 0

        for (const question of block.left_section.questions) {
          const parsedQuestion = parseQuestion(
            pageIdIndexLookupMap,
            previousPagesQuestionTotal,
            previousBlocksQuestionsTotal,
            currentLeftSectionQuestionIndex,
            parsedBlock,
            question,
            numberQuestionsCannotBeAnswered + answeredQuestionCount,
            displayNumbers,
            SectionPosition.Left,
            pdfConfig
          )
          // Note intentionally not updating other counters.
          currentLeftSectionQuestionIndex += 1
          parsedBlock.sections[SectionPosition.Left].questionIds.push(question.id)
          parsedLeftRightSectionQuestions.push(parsedQuestion)
        }

        let currentRightSectionQuestionIndex = 0
        for (const question of block.right_section.questions) {
          const parsedQuestion = parseQuestion(
            pageIdIndexLookupMap,
            previousPagesQuestionTotal,
            previousBlocksQuestionsTotal,
            currentRightSectionQuestionIndex,
            parsedBlock,
            question,
            numberQuestionsCannotBeAnswered + answeredQuestionCount,
            displayNumbers,
            SectionPosition.Right,
            pdfConfig
          )
          // Note intentionally not updating other counters.
          currentRightSectionQuestionIndex += 1
          parsedBlock.sections[SectionPosition.Right].questionIds.push(question.id)
          parsedLeftRightSectionQuestions.push(parsedQuestion)
        }

        // Remediation of technical possibility for question sequence to swap back and forth between left and right below.
        parsedLeftRightSectionQuestions.sort((a, b) => a.location.indexInPage - b.location.indexInPage)
        for (const parsedQuestion of parsedLeftRightSectionQuestions) {
          parsedQuestion.previousQuestionId = previousQuestion?.id ?? null
          parsedQuestion.previousQuestionAnswerable = previousQuestion ? previousQuestion.answerable : false
          parsedQuestion.show = (parsedQuestion.location.indexInAssessment - (numberQuestionsCannotBeAnswered + answeredQuestionCount)) === 0
          if (!parsedQuestion.answerable) {
            numberQuestionsCannotBeAnswered += 1
          } else if (parsedQuestion.respondentAnswer.answered) {
            answeredQuestionCount += 1
          }
          parsedQuestion.previousQuestionOnPageIsContentOnly = previousQuestionOnPageIsContentOnly
          previousQuestionOnPageIsContentOnly = previousQuestionOnPageIsContentOnly || ((parsedQuestion.type === QuestionType.SectionHeader) || (parsedQuestion.type === QuestionType.TextBlock))
          previousQuestion = parsedQuestion
          questions.set(parsedQuestion.id, parsedQuestion)
          parsedBlock.questionIds.push(parsedQuestion.id)
          parsedPage.questionIds.push(parsedQuestion.id)
          if (parsedQuestion.show) {
            lastShownQuestionId = parsedQuestion.id
            anyPageQuestionsShown = true
            anyBlockQuestionsShown = true
          }
        }

        previousBlocksQuestionsTotal += (currentRightSectionQuestionIndex + currentLeftSectionQuestionIndex)
      }
      if (anyBlockQuestionsShown) {
        parsedBlock.show = true
      }
      blocks.push(parsedBlock)
      parsedPage.blockIds.push(parsedBlock.id)
      currentBlockIndex += 1
    }
    if (anyPageQuestionsShown) {
      parsedPage.show = true
    }
    currentPageIndex += 1
    previousPagesQuestionTotal += uniquePageQuestionIds.length
    pages.push(parsedPage)
  }
  const activeQuestionId = lastShownQuestionId ?? [...questions.values()].shift()?.id ?? null
  const activeQuestion = activeQuestionId ? questions.get(activeQuestionId) : null

  const focus = {
    transitionToQuestionId: activeQuestionId,
    lastAnsweredQuestionId: activeQuestion?.respondentAnswer?.answered ? activeQuestionId : null,
    promptTransitionQuestionId: null
  }
  return { questions, focus, pages, blocks }
}

function parseQuestion (
  pageIdIndexLookupMap,
  previousPagesQuestionTotal,
  previousBlocksQuestionsTotal,
  currentSectionQuestionIndex,
  parsedBlock,
  question,
  numberPreviousQuestionsResolved,
  displayNumbers,
  sectionPosition,
  pdfConfig = null
) {
  const { displayCompetencies, displayCorrectAnswers, displayLogicIndicators, displayAnswerScores } = (pdfConfig ?? {})
  const locator = {
    questionId: question.id,
    blockSectionPosition: sectionPosition,
    indexInSection: currentSectionQuestionIndex,
    blockId: parsedBlock.id,
    indexInBlock: pageIdIndexLookupMap[question.id] - previousBlocksQuestionsTotal,
    pageId: parsedBlock.pageId,
    pageIndex: parsedBlock.pageIndex,
    indexInPage: pageIdIndexLookupMap[question.id],
    indexInAssessment: pageIdIndexLookupMap[question.id] + previousPagesQuestionTotal,
    assessmentId: parsedBlock.assessmentId
  }
  const media = !question.media
    ? null
    : {
        id: question.media.id,
        height: question.media.height ?? null,
        width: question.media.width ?? null,
        typeId: question.media.type,
        type: mediaTypeFromId(question.media.type),
        link: question.media.link ?? ''
      }
  const questionAnswerable = isAnswerable(question.type)
  const answerAdditional = getQuestionDecodedAdditional(question)
  const selectedAnswerId = question.display_respondent_answer?.answer?.id?.toString() ?? question.display_respondent_answer?.answers?.map(elem => elem.id?.toString() ?? null)?.[0] ?? null
  const respondentAnswer = {
    id: question.id,
    answerId: selectedAnswerId,
    answerIds: question.display_respondent_answer?.answers?.map(elem => elem.id?.toString() ?? null) ?? (selectedAnswerId ? [selectedAnswerId] : []),
    additionalAnswer: answerAdditional.answer ?? '',
    additionalComment: answerAdditional.comment ?? '',
    answered: false,
    skipped: false,
    declined: false,
    shouldAnswer: questionAnswerable,
    lastSyncId: 0,
    lastModificationId: 0,
    pageId: locator.pageId,
    pageIndex: locator.pageIndex,
    assessmentId: parsedBlock.assessmentId,
    indexInAssessment: locator.indexInAssessment
  }
  if (respondentAnswer.answerId || respondentAnswer.additionalAnswer || respondentAnswer.answerIds.length) {
    respondentAnswer.answered = true
  }
  const showCorrectAnswer = !!(displayCorrectAnswers || question.show_correct_answer)
  const showQuestionsAnswerScores = displayAnswerScores && question.is_scored
  const parsedAnswers = (question.display_answers ?? question.answers ?? [])?.map(
    (answer, index) => {
      return {
        id: answer.id,
        index: index,
        content: answer.content ?? '',
        correct: showCorrectAnswer ? (answer.correct ?? false) : null,
        score: (showQuestionsAnswerScores && answer.score) ? answer.score : null
      }
    }
  ) ?? []

  // Note that the show state will be overwritten, especially for left/right blocks as they can change order.
  return {
    id: question.id,
    content: question.display_content ?? question.content ?? '',
    showNumber: displayNumbers && !!question.number && isNumerable(question.type),
    number: displayNumbers ? (question.number ?? null) : null,
    vertical: !!question.vertical,
    comment: !!question.comment,
    isDropdown: !!question.is_dropdown,
    mediaLocation: questionMediaLocationFromId(question.top),
    large: parsedBlock.hasCenter,
    type: question.type,
    answers: parsedAnswers,
    respondentAnswer: respondentAnswer,
    media: media,
    loadMedia: locator.indexInAssessment <= 5,
    competencies: displayCompetencies ? (question.competencies ?? []) : [],
    location: locator,
    showCorrectAnswer: showCorrectAnswer,
    answerable: questionAnswerable,
    answerRequired: questionAnswerable && !!question.required,
    show: (locator.indexInAssessment - numberPreviousQuestionsResolved) === 0,
    hideFromLogic: false,
    previousQuestionId: null,
    previousQuestionAnswerable: false,
    previousQuestionOnPageIsContentOnly: false,
    showHasLogicIndicator: !!(displayLogicIndicators && question.logic?.length),
    validationType: question.validation_type ?? null
  }
}

function getQuestionDecodedAdditional (question) {
  if (!question.display_respondent_answer?.additional) {
    return {}
  }
  try {
    return JSON.parse(question.display_respondent_answer.additional)
  } catch (error) {
    console.error('Json parse error for question respondent answer.', error, question, question.display_respondent_answer)
    return {}
  }
}

function parseSectionToBlock (parsedBlock, section, sectionPosition) {
  return {
    position: sectionPosition,
    isFinalPageFinalBlockSection: parsedBlock.isFinalPageFinalBlock,
    questionIds: [],
    large: sectionPosition === SectionPosition.Center,
    blockId: parsedBlock.id,
    pageIndex: parsedBlock.pageIndex
  }
}
