import { ApiMethods, apiSlice, ApiTags } from '../../../api';
import { paramsToQuery } from '../../../util/queries';

export const boostApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBoostInvitableApplicants: builder.query({
      query: ({ id, ...params }) => `cycles/${id}/invitable/applicants${paramsToQuery(params)}`,
      providesTags: (result, error, { id: cycleId }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.CycleBoostInvitableApplicant, id: id })),
              { type: ApiTags.CyclesBoostInvitableApplicants, id: cycleId }
            ]
          : [{ type: ApiTags.CyclesBoostInvitableApplicants, id: cycleId }]
    }),
    sendBoostBulkInvites: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `cycles/${id}/boost/bulk/invite`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.CyclesBoostInvitableApplicants, id: id }]
    })
  })
})

export const { useGetBoostInvitableApplicantsQuery, useSendBoostBulkInvitesMutation } = boostApi
