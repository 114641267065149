/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import ReactTable, { ReactTableContextsProvider } from '../../core/ReactTable/ReactTable';
import {
  useCycleApplicantsDetailsList,
  useCycleDegrees,
  useCycle,
  useCycleRegions,
  useCycleStatuses,
  useCycleTableModuleInfos,
  useLazyCycleApplicantsDetailsList
} from './CycleHooks';
import { CycleContext, NamespaceContext, TableDataContext, TableStylesContext } from '../../core/ReactTable/TableContexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../core/ReactTable/paramsSlice';
import { useCycleApplicantsDetailListTable } from './DetailViewHooks';
import { useAccount } from '../../util/Hooks';
import { Center, Grid, Loader, Space } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { CycleDetailViewActionsContainer } from './DetailViewApp';
import { DetailViewSelectedHandler } from './DetailViewSelectedHandler';
import { BetaCardWrapper } from './ApplicantCard/ApplicantCard';
import { blankDetailViewState, createInitialDetailViewState, detailViewReducer } from './DetailViewState';
import { openCard, selectAllOpenedKeys } from './detailViewSlice';
import { useGetCycleStatusesQuery, useGetCycleTableModuleInfosQuery } from '../../../redux/query/hire/cyclesApi.slice';

export function DetailViewTableApp () {
  const { cycleId } = useParams()
  const [detailState, detailDispatch] = useReducer(detailViewReducer, blankDetailViewState, createInitialDetailViewState)
  const openedCards = useSelector(state => selectAllOpenedKeys(state))

  const account = useAccount()

  useEffect(() => {
    const preventDefault = (e) => e.preventDefault()
    document.addEventListener('gesturestart', preventDefault)
    document.addEventListener('gesturechange', preventDefault)

    return () => {
      document.removeEventListener('gesturestart', preventDefault)
      document.removeEventListener('gesturechange', preventDefault)
    }
  }, [])

  const { data: statuses } = useGetCycleStatusesQuery({ cycleId })
  const { data: modules } = useGetCycleTableModuleInfosQuery({ cycleId })

  const cycleData = useMemo(() => {
    const NOTES_ID = 11
    const _modules = modules?.items.filter(m => m.module.id !== NOTES_ID).map(m => m.module) ?? []
    console.log(_modules)

    return {
      id: cycleId,
      statuses: statuses?.items ?? [],
      modules: _modules
    }
  }, [statuses, modules, cycleId])

  return (
    <CycleContext.Provider value={cycleId}>
      <Grid align='stretch'>
        <Grid.Col>
          <DetailViewTableActionsBar />
          <DetailViewSelectedHandler account={account} />
          <DetailViewTableLoader cycleId={cycleId} account={account} state={detailState} dispatch={detailDispatch} />
        </Grid.Col>
      </Grid>
      {openedCards.length > 0 &&
        openedCards.map(cardId => (
          <BetaCardWrapper
            key={cardId}
            cardId={cardId}
            cycleData={cycleData}
            account={account}
            />
        ))
      }
    </CycleContext.Provider>
  )
}

export const DetailViewTableActionsBar = memo(function DetailViewTableActionsBar () {
  // TODO [detail rework] verified first script?
  return (
    <div className='detail-view'>
      <div style={{ float: 'right', height: 0, overflow: 'visible' }}>
        <CycleDetailViewActionsContainer containerName='react-app-container'/>
      </div>
    </div>
  )
})

const DetailViewTableLoader = memo(function DetailViewTableLoader ({ cycleId, account, state, dispatch }) {
  const [moduleInfos, modulesRefreshing] = useCycleTableModuleInfos(cycleId)
  const [statuses, statusesRefreshing] = useCycleStatuses(cycleId)
  const [regions, regionsRefreshing] = useCycleRegions(cycleId)
  const [educationOptions, educationOptionsRefreshing] = useCycleDegrees(cycleId)
  const [cycleDescription, descriptionRefreshing] = useCycle(cycleId)

  const anyLoading = modulesRefreshing || !account.email || statusesRefreshing || regionsRefreshing || educationOptionsRefreshing || descriptionRefreshing

  const statusTiers = useMemo(() => {
    if (!statuses?.items) {
      return []
    }
    const tiersMap = new Map()
    for (const status of statuses.items) {
      if (status.tier) {
        tiersMap.set(status.tier.id, status.tier)
      } else {
        console.warn('Did not find tier where expected in status.', { status, statuses })
      }
    }
    return [...tiersMap.values()]
  }, [statuses])

  return (
    <>
      {!!anyLoading && (
        <>
          <Space h='xxl' />
          <Center>
            <Loader type='bars' />
          </Center>
        </>
      )}
      {!anyLoading && (
        <DetailViewTable
          cycleId={cycleId}
          account={account}
          moduleInfos={moduleInfos}
          statuses={statuses}
          statusTiers={statusTiers}
          regions={regions}
          cycleDescription={cycleDescription}
          educationOptions={educationOptions}
          state={state}
          dispatch={dispatch}
        />
      )}
    </>
  )
})

const DetailViewTable = memo(function DetailViewTable (
  {
    cycleId,
    account,
    moduleInfos,
    statuses,
    statusTiers,
    regions,
    cycleDescription,
    educationOptions,
    namespace = 'cycle-detail-view',
    state
    // dispatch
  }
) {
  const [applicants] = useLazyCycleApplicantsDetailsList(namespace, cycleId)
  const dispatch = useDispatch()

  const handleRowClicked = useCallback((row) => {
    console.debug('Detail view table row clicked.', row)
    if (row === undefined) return true
    const applicantId = row.dataset.rowid
    const intApplicantId = parseInt(applicantId)
    const applicant = (applicants?.items?.filter(a => a.id === intApplicantId) ?? [])[0] ?? null
    if (!applicant) {
      console.warn('Unable to find applicant matching clicked id.', { applicantId, intApplicantId, cycleId, applicant, applicants })
      return true
    }

    dispatch(openCard({ id: intApplicantId }))
  }, [applicants, cycleId, dispatch])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = useCycleApplicantsDetailListTable(
    namespace, cycleId, account, moduleInfos, statuses, statusTiers, regions, cycleDescription, educationOptions
  )

  return (
    <ReactTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
    >
      <CycleDetailTableDataProvider cycleId={cycleId}>
        <CycleDetailTableStylesProvider>
          <ReactTable {...otherTableProps} onRowClick={handleRowClicked} />
        </CycleDetailTableStylesProvider>
      </CycleDetailTableDataProvider>
    </ReactTableContextsProvider>
  )
})

function CycleDetailTableStylesProvider ({ children }) {
  return (
    <TableStylesContext.Provider value={{ generateRowStyles }}>
      {children}
    </TableStylesContext.Provider>
  )
}

function CycleDetailTableDataProvider ({ cycleId, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useCycleApplicantsDetailsList(namespace, cycleId, !loaded)
  const querying = queryRunning || !loaded

  const currentTableDataContext = useMemo(() => {
    console.info('Updating CycleDetailTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Cycle detail table data provider updated.', { collection, querying, namespace, cycleId })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}

function generateRowStyles (applicant, currentStyles) {
  let styles = {}

  switch (applicant.status?.tier?.name) {
    case 'hired':
      styles.bg = 'green.1'
      break

    case 'not recommended':
      styles.bg = 'gray.2'
      break
  }

  styles = { ...styles, ...currentStyles }

  return styles
}
