import React, { useCallback, useEffect, useRef, useState } from 'react'
import Content from '../layout/Content'
import { Alert, Badge, Button, CloseButton, Divider, Fieldset, Group, LoadingOverlay, Paper, Select, SimpleGrid, Stack, Text, TextInput, Tooltip } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useForm } from '@mantine/form'
import OrganizationSelect from '../build/status_set/OrganizationSelect'
import { IconInfoCircle, IconPlus } from '@tabler/icons-react'
import { getSkillBuilderFacilitators, getSkillbuilderCycles } from '../../js/api/skillbuilder_repository'
import StringUtil from '../../js/util/StringUtil'
import { useAccount } from '../util/Hooks'
import _ from 'lodash'
import { apiSlice } from '../api';
import { useCreateSBCandidatesMutation } from './SBUsersApi';
import { GoBackButton } from '../core/GoBackButton'

const DEFAULT_CANDIDATE = {
  account: {
    firstName: '',
    lastName: '',
    email: ''
  },
  employeeId: '',
  position: '',
  facilitator: null,
  program: null,
  supervisor: {
    name: '',
    email: ''
  }
}
export default function AddCandidate () {
  return (
    <Content>
      <WrappedAddCandidate />
    </Content>
  )
}

function WrappedAddCandidate () {
  const [facilitators, setFacilitators] = useState([])
  const [programs, setPrograms] = useState([])
  const [defaultFacilitator, setDefaultFacilitator] = useState(null)
  const [defaultProgram, setDefaultProgram] = useState(null)
  const [saving, setSaving] = useState(false)
  const account = useAccount()
  const [lockedOrg, setLockedOrg] = useState('')
  const [, startSbUsersQuery] = useSBUsersQuery()
  const [createCandidates] = useCreateSBCandidatesMutation()

  const form = useForm({
    initialValues: {
      organization: null,
      candidates: [{ ...DEFAULT_CANDIDATE, id: `candidate-${StringUtil.makeId()}` }]
    },
    validate: {
      organization: (value) => !value ? 'Organization is required' : null,
      candidates: {
        account: {
          firstName: (value) => !value ? 'First name is required' : null,
          lastName: (value) => !value ? 'Last name is required' : null,
          email: (value) => !value ? 'Email is required' : null
        }
        // TODO: Figure out if we need to enforce any of this two for SB admins
      }
    },
    transformValues: values => {
      const submitValues = values.candidates.map(candidate => {
        const { id, ...rest } = candidate
        return rest
      })
      return {
        ...values,
        candidates: submitValues
      }
    }
  })
  const formRef = useRef(form)

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  const accessRef = useRef(account.access)

  useEffect(() => {
    if (account.id && (account.access.SB_FACILITATOR || account.access.SB_LOCATION_ADMIN || account.access.SB_SUPER_ADMIN)) {
      startSbUsersQuery(account.id).unwrap().then(data => {
        const { training_user: trainingUser, org_name: orgName } = data[0]
        setLockedOrg(orgName)
        setDefaultFacilitator(trainingUser.id.toString())
        formRef.current.setValues({
          organization: trainingUser.client_id,
          candidates: formRef.current.values.candidates.map(candidate => {
            return {
              ...candidate,
              facilitator: trainingUser.id.toString()
            }
          })
        })
      })
    }
    accessRef.current = account.access
  }, [account, startSbUsersQuery])

  useEffect(() => {
    const organization = form.values.organization
    if (organization) {
      if (accessRef.current.ADMIN || accessRef.current.SB_LOCATION_ADMIN || accessRef.current.SB_SUPER_ADMIN) {
        getSkillBuilderFacilitators(organization).then(data => {
          const formattedData = data.map(facilitator => ({
            value: facilitator.id.toString(),
            label: `${facilitator.account.first_name} ${facilitator.account.last_name}`
          }))
          if (formattedData.length > 0) {
            setFacilitators(formattedData)
          }
        })
      }

      getSkillbuilderCycles({ organization: organization, limit: 0, status: 0 }).then(data => {
        const formattedData = data.items.map(program => ({
          value: program.id.toString(),
          label: program.name
        }))
        if (formattedData.length > 0) {
          setPrograms(formattedData)
        }
      })
    }
  }, [form.values.organization])

  const handleOrganizationChange = useCallback((value) => {
    setFacilitators([])
    setPrograms([])
    setDefaultFacilitator(null)
    setDefaultProgram(null)
    formRef.current.setValues({
      candidates: formRef.current.values.candidates.map(candidate => {
        return {
          ...candidate,
          facilitator: null,
          program: null
        }
      })
    })

    formRef.current.getInputProps('organization').onChange(value)
  }, [])

  const removeCandidate = useCallback((index) => {
    formRef.current.setValues({
      ...formRef.current.values,
      candidates: formRef.current.values.candidates.filter((_, i) => i !== index)
    })
  }, [])

  const addCandidate = useCallback(() => {
    formRef.current.setValues({
      candidates: [
        ...formRef.current.values.candidates,
        {
          ...DEFAULT_CANDIDATE,
          id: `candidate-${StringUtil.makeId()}`,
          facilitator: defaultFacilitator,
          program: defaultProgram
        }
      ]
    })
  }, [defaultFacilitator, defaultProgram])

  useEffect(() => {
    formRef.current.setValues(currentValues => ({
      ...currentValues,
      candidates: currentValues.candidates.map((candidate) => {
        return {
          ...candidate,
          facilitator: defaultFacilitator
        }
      })
    }))
  }, [defaultFacilitator])

  useEffect(() => {
    formRef.current.setValues(currentValues => ({
      ...currentValues,
      candidates: currentValues.candidates.map((candidate) => {
        return {
          ...candidate,
          program: defaultProgram
        }
      })
    }))
  }, [defaultProgram])

  const AddCandidateButton = () => (
    <Group justify='space-between'>
      <Button onClick={addCandidate} leftSection={<IconPlus />} >Add {form.values.candidates.length > 0 ? 'Another' : 'a'} Candidate</Button>
      {form.values.candidates.length > 0 && <Badge variant='outline'>Adding +{form.values.candidates.length} candidate{form.values.candidates.length > 1 && 's'}</Badge>}
    </Group>
  )

  const submit = values => {
    setSaving(true)
    createCandidates(values).unwrap().then(response => {
      setSaving(false)
      formRef.current.setValues(currentValues => {
        return {
          ...currentValues,
          candidates: [{
            ...DEFAULT_CANDIDATE,
            id: `candidate-${StringUtil.makeId()}`,
            facilitator: defaultFacilitator,
            program: defaultProgram
          }]
        }
      })
      showNotification({
        title: 'Candidates Added',
        message: 'Candidates have been added successfully',
        color: 'success'
      })
    }).catch(err => {
      setSaving(false)
      if (err.status === 400 && err.data?.type === 'validation_error') {
        _.forEach(err.data.errors, error => {
          showNotification({
            title: 'Something went wrong',
            message: error,
            color: 'red',
            autoClose: 3000
          })
        })
      } else {
        showNotification({
          message: 'Something went wrong',
          color: 'red',
          autoClose: 3000
        })
      }
    })
  }

  return (
    <>
      <LoadingOverlay visible={saving} zIndex={148} overlayProps={{ radius: 'sm', blur: 2 }} />
    <form onSubmit={form.onSubmit(values => submit(values))}>
      <Stack>
        <Alert icon={<IconInfoCircle />}>
          Use the form below to add one or more candidates into the system. You can use the &#39;Add Another Candidate&#39; button to add as many candidates as you need.
          You can also &#34;tab in&#34; after the Program field to add another candidate while entering data.
          The &#39;Default Program&#39; and &#39;Default Facilitator&#39; fields can be used to automatically fill in the respective fields for each new candidate.
        </Alert>
        <Fieldset legend='Default Settings'>
          <Text>Changing these fields will update all candidates on the page</Text>
          {
            account.access.ADMIN
              ? <OrganizationSelect
            withAllOption={false}
            placeholder='Stang Decision Systems'
            clearable
            required
            {...form.getInputProps('organization')}
            onChange={handleOrganizationChange}
            />
              : <TextInput label='Organization' value={lockedOrg} disabled />
          }
          {
          account.access.SB_FACILITATOR && !(account.access.ADMIN || account.access.SB_LOCATION_ADMIN || account.access.SB_SUPER_ADMIN)
            ? <TextInput label='Default Skillbuilder Facilitator' value={`${account.first_name} ${account.last_name}`} disabled />
            : <Select
            label='Default Facilitator'
            value={defaultFacilitator}
            data={facilitators}
            onChange={setDefaultFacilitator}
            placeholder={facilitators.length > 0 ? 'Select a facilitator' : 'No facilitators found'}
            clearable
            />
          }
          <Select
            label='Default Skillbuilder Program'
            value={defaultProgram}
            data={programs}
            onChange={setDefaultProgram}
            placeholder={programs.length > 0 ? 'Select a program' : 'No programs found'}
            clearable
            />

        </Fieldset>
        <AddCandidateButton />
        <SimpleGrid cols={1}>
        {form.values.candidates?.map((candidate, index) => {
          return (
            <Paper key={candidate.id} bg='gray.0' withBorder>
              <Group justify='space-between'>
                <h2>Candidate Info</h2>
                <Tooltip label='Remove candidate'>
                  <CloseButton onClick={() => removeCandidate(index)} variant='danger-subtle' tabIndex={-1} />
                </Tooltip>
              </Group>
              <TextInput
                label='First Name'
                required
                placeholder='Jane'
                {...form.getInputProps(`candidates.${index}.account.firstName`)}
                />
              <TextInput
                label='Last Name'
                required
                placeholder='Doe'
                {...form.getInputProps(`candidates.${index}.account.lastName`)}
                />
              <TextInput
                type='email'
                label='Email'
                required
                placeholder='jane-doe@email.com'
                {...form.getInputProps(`candidates.${index}.account.email`)}
                />
              <TextInput
                label='Employee ID'
                placeholder='1234567'
                {...form.getInputProps(`candidates.${index}.employeeId`)}
                />
              <TextInput
                label='Position'
                placeholder='Service Delivery Manager'
                {...form.getInputProps(`candidates.${index}.position`)}
                />
              {
                account.access.SB_FACILITATOR && !(account.access.ADMIN || account.access.SB_LOCATION_ADMIN || account.access.SB_SUPER_ADMIN)
                  ? <TextInput label='Facilitator' value={`${account.first_name} ${account.last_name}`} disabled />
                  : <Select
                      data={facilitators}
                      label='Facilitator'
                      placeholder={facilitators.length > 0 ? 'Select a facilitator' : 'No facilitators found'}
                      {...form.getInputProps(`candidates.${index}.facilitator`)}
                      />}
              <Select
                data={programs}
                label='Program'
                placeholder={programs.length > 0 ? 'Select a program' : 'No programs found'}
                {...form.getInputProps(`candidates.${index}.program`)}
                />
              <Divider my='lg' />
              <h3>Supervisor (Optional)</h3>
              <TextInput label='Name' tabIndex={-1} {...form.getInputProps(`candidates.${index}.supervisor.name`)} />
              <TextInput type='email' tabIndex={-1} label='Email' {...form.getInputProps(`candidates.${index}.supervisor.email`)} />
            </Paper>
          )
        })}
        </SimpleGrid>
        {form.values.candidates.length > 0 && <AddCandidateButton />}
        <Group>
          <Button type='submit' color='success' >Save Candidates</Button>
          <GoBackButton />
        </Group>
        </Stack>
      </form>
    </>
  )
}

function useSBUsersQuery () {
  const [trigger, data] = apiSlice.endpoints.getTrainingUser.useLazyQuery()
  return [data ?? null, trigger]
}
