import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries'

export const emailTemplatesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEmailTemplate: builder.query({
      query: id => `templates/${id}`,
      providesTags: (result, error, id) => [{ type: ApiTags.EmailTemplates, id: id }]
    }),
    getEmailTemplates: builder.query({
      query: (params) => `templates${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.EmailTemplates, id: id })),
              { type: ApiTags.EmailTemplates, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.EmailTemplates, id: ApiAbstractIds.PartialList }]
    }),
    deleteEmailTemplate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `templates/${id}`,
        method: ApiMethods.Delete,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.EmailTemplates, id: id }]
    })
  })
})

export const {
  useGetEmailTemplateQuery,
  useGetEmailTemplatesQuery,
  useDeleteEmailTemplateMutation
} = emailTemplatesApi
