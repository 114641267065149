import React, { useMemo } from 'react'
import { SimpleGrid } from '@mantine/core'
import BoostTable from './BoostTable';
import { useParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { useBoostLimitInformation } from './BoostHooks';

const namespace = 'boostInvites'

export default function BoostCycle (job) {
  const { cycleId } = useParams()
  const limitInformation = useBoostLimitInformation(cycleId)

  useMemo(() => {
    if (limitInformation?.reached) {
      showNotification({
        title: 'Limit Reached',
        message: 'The invite limit for this cycle has been reached',
        color: 'yellow',
        autoClose: 3000
      })
    }
  }, [limitInformation])

  return (
    <SimpleGrid>
      <BoostTable
        job={job}
        namespace={namespace}
      />
    </SimpleGrid>
  )
}
