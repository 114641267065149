import { ApiMethods, apiSlice, ApiTags } from '../../../../api';
import { cycleListTag, paramsToQuery } from '../../../../util/queries';
import { injectNullFlags } from '../queryUtil';

export const StageProgressesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProgressesForCycle: builder.query({
      query: ({ cycleId, ...params }) => `invites/progresses/cycles/${cycleId}/list${paramsToQuery(injectNullFlags(params))}`,
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.StageProgresses, id: id })),
              { type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) }]
    }),
    getProgressDetail: builder.query({
      query: ({ progressId }) => `invites/progresses/${progressId}`,
      providesTags: ({ id }, _, { progressId: progressIdParam, cycleId: cycleIdParam }) =>
        [{ type: ApiTags.StageProgresses, id: id ?? progressIdParam }, { type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) }]
    }),
    stageProgressLock: builder.mutation({
      query: ({ progressId, cycleId, unlock }) => ({
        url: unlock ? `invites/progresses/${progressId}/unlock` : `invites/progresses/${progressId}/lock`,
        method: ApiMethods.Post,
        body: {}
      }),
      invalidatesTags: (result, error, { progressId: progressIdParam, cycleId: cycleIdParam }) => [
        { type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.StageProgresses, id: progressIdParam }
      ]
    })
  })
})

export const { useGetProgressesForCycleQuery, useGetProgressDetailQuery, useStageProgressLockMutation } = StageProgressesApi
