import React from 'react'
import BoostCycle from './BoostCycle';

export default function BoostCycleWrapper () {
  const job =
    document.getElementById('areact-app-container')?.getAttribute('data-job')
      ? [document.getElementById('react-app-container')?.getAttribute('data-job')]
      : []

  return (
    <BoostCycle job={job}/>
  )
}
